import parseISO from 'date-fns/parseISO'
import { format, utcToZonedTime } from 'date-fns-tz'

import { useQueryParams } from 'src/lib/hooks'

export const dateFormat = (date) => {
  const localDate = parseISO(date)
  const utcDate = utcToZonedTime(localDate, 'GMT')

  if (!date) {
    return <span className="text-gray-400">(none)</span>
  } else {
    return (
      <span
        className="cursor-help"
        title={`${format(localDate, 'yyyy-MM-dd HH:mm:ss z')}`}
        dangerouslySetInnerHTML={{
          __html: format(utcDate, "yyyy-MM-dd'<br>'HH:mm:ss z", {
            timeZone: 'GMT',
          }),
        }}
      />
    )
  }
}

export const enabled = () => (
  <div className="w-6 mx-auto">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
        className="fill-current text-green-600"
      />
    </svg>
  </div>
)

export const disabled = () => {}

export const mergeParams = (newParam = {}) => {
  const params = useQueryParams()

  return Object.assign(params, newParam)
}

export const titleCase = (text) => {
  if (text === null || text === '') {
    return text
  } else {
    text = text.toString()
  }

  return text.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const joinJsxWith = (array, joinWith) => {
  return array.map((element, i) => {
    let divider = i < array.length - 1 && <span> {joinWith} </span>

    return (
      <span key={i}>
        <span>{element}</span>
        {divider}
      </span>
    )
  })
}

export const headersToList = (headers) => {
  const items = []
  const jsonHeaders = JSON.parse(headers)

  for (let header in jsonHeaders) {
    items.push(
      <li key={header}>
        {header}: {jsonHeaders[header]}
      </li>
    )
  }

  return items
}

export const statusColor = (status) => {
  if (status < 200) {
    return 'text-gray-600'
  } else if (status >= 200 && status < 400) {
    return 'text-green-600'
  } else if (status >= 400) {
    return 'text-red-600'
  }
}
