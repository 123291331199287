import { useLocation } from '@redwoodjs/router'

// Returns an object containing keys/values for any query params
//
//   /jobs?filter=all => { filter: 'all' }
export const useQueryParams = () => {
  const { search } = useLocation()
  let params = {}

  search
    .substring(1, search.length)
    .split('&')
    .forEach((part) => {
      const parts = part.split('=')
      params[parts[0]] = parts[1]
    })

  return params
}
