import { Link, routes } from '@redwoodjs/router'

import { dateFormat } from 'src/lib/helpers'

export const QUERY = gql`
  query ApplicationsQuery {
    applications {
      name
      token
      createdAt
      jobsCount
      scheduledJobsCount
    }
  }
`

export const Loading = () => (
  <div className="text-base text-center text-gray-500">Loading...</div>
)

export const Empty = () => (
  <div className="text-base text-center text-gray-500">
    No applications yet—you need an application before you can create a job.{' '}
    <Link to={routes.newApplication()}>Let's add one!</Link>
  </div>
)

export const Failure = ({ error }) => (
  <div className="text-base text-center text-red-700">{error.message}</div>
)

export const Success = ({ applications }) => {
  const reveal = (event) => {
    event.preventDefault()

    const tokenElement = event.currentTarget.previousSibling
    const content = tokenElement.textContent
    tokenElement.textContent = tokenElement.dataset.token
    tokenElement.dataset.token = content
  }

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th>Name</th>
          <th>Token</th>
          <th>Created</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {applications.map((app, index) => (
          <tr key={index}>
            <td>{app.name}</td>
            <td>
              <div className="flex items-center">
                <div className="font-mono" data-token={app.token}>
                  ••••••••••••••••••••••••••••••••
                </div>
                <a
                  href="#"
                  onClick={reveal}
                  className="ml-2 bg-blue-100 hover:bg-blue-200 text-blue-700 px-2 py-1 rounded-sm text-xs"
                >
                  Show
                </a>
              </div>
            </td>
            <td>{dateFormat(app.createdAt)}</td>
            <td className="text-right">
              <Link
                to={routes.applicationJobs({ applicationName: app.name })}
                className="whitespace-no-wrap text-blue-700 underline"
              >
                {app.jobsCount} Jobs
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
