import { useAuth } from '@redwoodjs/auth'
import { Link, NavLink, routes } from '@redwoodjs/router'
import Flash from 'src/components/Flash'

import { joinJsxWith } from 'src/lib/helpers'

const AppLayout = ({ breadcrumbs = [], title, action, children }) => {
  const { currentUser, logOut } = useAuth()

  return (
    <div className="h-full flex flex-col md:flex-row">
      <aside className="w-full bg-indigo-100 md:w-56 border-r border-indigo-200 pb-4 md:pb-0">
        <header className="flex items-center justify-between md:justify-center m-4 px-4 md:px-8">
          <Link to={routes.applications()}>
            <img src="/images/logo.svg" className="w-16" />
          </Link>
          <button
            className="md:hidden ml-2 bg-gray-700 text-xs text-white px-2 py-1 rounded"
            onClick={logOut}
          >
            Log Out
          </button>
        </header>
        <nav>
          <ul className="flex items-center justify-between md:block text-sm mt-8 md:mt-12 md:mb-6 px-4 md:px-8 ">
            <li>
              <NavLink
                to={routes.applications()}
                className="flex items-center"
                activeClassName="active"
              >
                <div className="icon w-6 mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M7 17h10V7H7v10zm2-8h6v6H9V9z"
                      className="fill fill-current text-gray-400"
                    />
                    <path
                      d="M21 11V9h-2V7c0-1.1-.9-2-2-2h-2V3h-2v2h-2V3H9v2H7c-1.1 0-2 .9-2 2v2H3v2h2v2H3v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2zm-4 6H7V7h10v10zm-2-8H9v6h6V9zm-2 4h-2v-2h2v2z"
                      className="outline fill-current text-gray-600"
                    />
                  </svg>
                </div>
                <span className="text-gray-600 font-medium">Applications</span>
              </NavLink>
            </li>
            <li className="md:mt-4">
              <NavLink
                to={routes.jobs()}
                className="flex items-center"
                activeClassName="active"
              >
                <div className="icon w-6 mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M12 6c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm3.75 10.85L11 14V8h1.5v5.25l4 2.37-.75 1.23z"
                      className="fill fill-current text-gray-400"
                    />
                    <path
                      d="M12.5 8H11v6l4.75 2.85.75-1.23-4-2.37zm4.837-6.19l4.607 3.845-1.28 1.535-4.61-3.843zm-10.674 0l1.282 1.536L3.337 7.19l-1.28-1.536zM12 4c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"
                      className="outline fill-current text-gray-600"
                    />
                  </svg>
                </div>
                <span className="text-gray-600 font-medium">Jobs</span>
              </NavLink>
            </li>
            <li className="md:mt-4">
              <NavLink
                to={routes.jobResults()}
                className="flex items-center"
                activeClassName="active"
              >
                <div className="icon w-6 mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M5 19h14V5H5v14zm2.41-7.41L10 14.17l6.59-6.59L18 9l-8 8-4-4 1.41-1.41z"
                      className="fill fill-current text-gray-400"
                    />
                    <path
                      d="M18 9l-1.41-1.42L10 14.17l-2.59-2.58L6 13l4 4zm1-6h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64S3 4.72 3 5v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"
                      className="outline fill-current text-gray-600"
                    />
                  </svg>
                </div>
                <span className="text-gray-600 font-medium">Job Results</span>
              </NavLink>
            </li>
          </ul>
          <hr className="hidden md:block border-indigo-200" />
          <ul className="hidden md:block flex items-center justify-between text-sm my-4 md:my-6 px-4 md:px-8">
            <li>
              <a href="//docs.repeater.dev" className="flex items-center">
                <div className="icon w-6 mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M13 13l-3-2.25L7 13V4H6v16h12V4h-5z"
                      className="fill fill-current text-gray-400"
                    />
                    <path
                      d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 4h2v5l-1-.75L9 9V4zm9 16H6V4h1v9l3-2.25L13 13V4h5v16z"
                      className="outline fill-current text-gray-600"
                    />
                  </svg>
                </div>
                <span className="text-gray-600 font-medium">Docs</span>
              </a>
            </li>
            <li className="md:mt-4">
              <a
                href="https://github.com/redwoodjs/repeater-issues/issues"
                target="_blank"
                rel="nofollow"
                className="flex items-center"
              >
                <div className="icon w-6 mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M15.83 9.65L15.46 9c-.3-.53-.71-.96-1.21-1.31l-.61-.42-.68-.16C12.63 7.04 12.32 7 12 7c-.31 0-.63.04-.94.11l-.74.18-.57.4c-.49.34-.91.79-1.21 1.31l-.37.65-.1.65c-.04.23-.07.48-.07.7v4c0 .22.03.47.07.7l.1.65.37.65c.72 1.24 2.04 2 3.46 2s2.74-.77 3.46-2l.37-.64.1-.65c.04-.24.07-.49.07-.71v-4c0-.22-.03-.47-.07-.7l-.1-.65zM14 16h-4v-2h4v2zm0-4h-4v-2h4v2z"
                      className="fill fill-current text-gray-400"
                    />
                    <path
                      d="M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5s-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-4 4v3c0 .22-.03.47-.07.7l-.1.65-.37.65c-.72 1.24-2.04 2-3.46 2s-2.74-.77-3.46-2l-.37-.64-.1-.65C8.03 15.47 8 15.22 8 15v-4c0-.22.03-.47.07-.7l.1-.65.37-.65c.3-.52.72-.97 1.21-1.31l.57-.39.74-.18c.31-.08.63-.12.94-.12.32 0 .63.04.95.12l.68.16.61.42c.5.34.91.78 1.21 1.31l.38.65.1.65c.04.22.07.47.07.69v1zm-6 2h4v2h-4zm0-4h4v2h-4z"
                      className="outline fill-current text-gray-600"
                    />
                  </svg>
                </div>
                <span className="text-gray-600 font-medium">Bug Report</span>
              </a>
            </li>
          </ul>
        </nav>
      </aside>
      <main className="flex-1 bg-white overflow-y-scroll">
        <header className="hidden md:flex items-center justify-end p-4 border-b">
          <div className="text-sm">{currentUser.email}</div>
          <button
            className="ml-2 bg-gray-700 text-xs text-white px-2 py-1 rounded"
            onClick={logOut}
          >
            Log Out
          </button>
        </header>
        <section className="p-4 md:p-8 relative">
          <Flash />
          <div className="flex justify-between items-center">
            <div>
              {breadcrumbs.length ? (
                <div className="text-sm">
                  {joinJsxWith(
                    breadcrumbs,
                    <span className="mx-1 text-gray-500"> » </span>
                  )}
                </div>
              ) : null}
              <h2 className="font-semibold text-2xl">{title}</h2>
            </div>
            {action && <div className="flex-shrink">{action}</div>}
          </div>
          <div className="text-sm mt-8 overflow-y-scroll">{children}</div>
        </section>
      </main>
    </div>
  )
}

export default AppLayout
