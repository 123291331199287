import JobResultRow from 'src/components/JobResultRow/JobResultRow'

const JobResultsTable = ({ jobResults, includeJob }) => {
  return (
    <table className="mt-4 w-full">
      <thead>
        <tr>
          <th className="text-center">Run</th>
          {includeJob && <th>Job</th>}
          <th className="text-center">Status</th>
          <th>Headers</th>
          <th>Body</th>
          <th>Run At</th>
          <th className="text-right">Duration</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {jobResults.map((result, index) => (
          <JobResultRow
            key={index}
            jobResult={result}
            includeJob={includeJob}
          />
        ))}
      </tbody>
    </table>
  )
}

export default JobResultsTable
