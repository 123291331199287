import { routes } from '@redwoodjs/router'
import Filters from 'src/components/Filters'

const JobFilters = ({ applicationName, filter }) => {
  const jobRoute = (filter) => {
    if (applicationName) {
      return routes.applicationJobs({ applicationName, filter })
    } else {
      return routes.jobs({ filter })
    }
  }

  return (
    <Filters
      filters={['All', 'Scheduled', 'Success', 'Failure', 'Pending']}
      selected={filter}
      route={jobRoute}
    />
  )
}

export default JobFilters
