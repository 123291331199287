import { Link } from '@redwoodjs/router'
import { paramCase } from 'param-case'

const Filters = ({ filters, route, selected }) => {
  return (
    <ul className="flex border-b filters">
      {filters.map((filter, i) => {
        return (
          <li
            key={i}
            className={`${paramCase(filter) === selected ? 'active' : ''} pb-2`}
          >
            <Link to={route(paramCase(filter))} className="text-gray-700 px-4">
              {filter}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Filters
