import { useFlash } from '@redwoodjs/web'
import { useEffect } from 'react'

const Flash = () => {
  const { messages, dismissMessage } = useFlash()

  useEffect(() => {
    setTimeout(() => {
      messages.forEach((message) => {
        dismissMessage(message.id)
      })
    }, 3000)
  }, [])

  if (!messages.length) {
    return null
  }

  return (
    <div className="absolute inset-0 m-8">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`block transition duration-200 ${message.classes} text-white text-center p-2 rounded`}
        >
          {message.text}
        </div>
      ))}
    </div>
  )
}

export default Flash
