import { Link, routes } from '@redwoodjs/router'

import DetailIcon from 'src/components/icons/DetailIcon'
import DeleteIcon from 'src/components/icons/DeleteIcon'
import { dateFormat, statusColor } from 'src/lib/helpers'

const truncate = (text, length) => {
  if (text === '' || text === null) {
    return <span className="text-gray-400">(none)</span>
  } else if (text.length <= length) {
    return text
  } else {
    return text.substring(0, length) + '...'
  }
}

const JobResultRow = ({ jobResult, includeJob }) => {
  return (
    <tr>
      <td className="text-center">{jobResult.run}</td>
      {includeJob && (
        <td className="whitespace-no-wrap">
          <Link
            to={routes.applicationJob({
              applicationName: jobResult.job.application.name,
              name: jobResult.job.name,
            })}
            className="underline"
          >
            {jobResult.job.name}
          </Link>
        </td>
      )}
      <td
        className={`font-mono text-center font-medium ${statusColor(
          jobResult.status
        )}`}
      >
        {jobResult.status}
      </td>
      <td className="text-xs font-mono" title={jobResult.headers}>
        {truncate(jobResult.headers, 40)}
      </td>
      <td className="text-xs font-mono" title={jobResult.body}>
        {truncate(jobResult.body, 40)}
      </td>
      <td>{dateFormat(jobResult.runAt)}</td>
      <td className="text-right">{jobResult.duration}ms</td>
      <td>
        <ul className="flex items-center justify-end">
          <li className="mx-1">
            <Link
              to={routes.applicationJobJobResult({
                applicationName: jobResult.job.application.name,
                jobName: jobResult.job.name,
                run: jobResult.run,
              })}
              title="View details"
            >
              <DetailIcon />
            </Link>
          </li>
          {/* <li className="mx-1">
                  <Link
                    to={routes.applicationJobJobjobResult({
                      applicationName: jobResult.job.application.name,
                      jobName: jobResult.job.name,
                      run: jobResult.run,
                    })}
                    title="Delete"
                  >
                    <DeleteIcon />
                  </Link>
                </li> */}
        </ul>
      </td>
    </tr>
  )
}

export default JobResultRow
