import { Link, routes } from '@redwoodjs/router'

import DetailIcon from 'src/components/icons/DetailIcon'
import EditIcon from 'src/components/icons/EditIcon'
import DeleteIcon from 'src/components/icons/DeleteIcon'

import { dateFormat, enabled, disabled } from 'src/lib/helpers'

const truncate = (text) => {
  if (text.length > 30) {
    return (
      text.substring(0, 15) +
      '...' +
      text.substring(text.length - 15, text.length)
    )
  } else {
    return text
  }
}

const jobResultsRoute = (job, filter) => {
  return routes.applicationJobJobResults({
    applicationName: job.application.name,
    jobName: job.name,
    filter,
  })
}

const JobRow = ({ job, applicationName }) => {
  return (
    <tr>
      {!applicationName && <td>{job.application.name}</td>}
      <td className="whitespace-no-wrap">
        <strong>{job.name}</strong>
      </td>
      <td title={job.endpoint}>
        <span className="inline-block bg-gray-300 text-gray-600 rounded-sm px-1 text-xs font-semibold mr-2">
          {job.verb}
        </span>
        <span className="text-xs">{truncate(job.endpoint)}</span>
      </td>
      <td>{job.runEvery}</td>
      <td>{dateFormat(job.lastRunAt)}</td>
      <td>{dateFormat(job.nextRunAt)}</td>
      <td>{job.enabled ? enabled() : disabled()}</td>
      <td className="text-center whitespace-no-wrap">
        {job.successCount > 0 && (
          <Link
            to={jobResultsRoute(job, 'success')}
            title={`${job.failureCount} successful run(s)`}
            className="inline-block bg-green-100 hover:bg-green-200 text-green-700 hover:text-green-800 border border-green-200 font-medium text-xs py-px px-2 rounded-sm"
          >
            {job.successCount}
          </Link>
        )}
        {job.failureCount > 0 && (
          <Link
            to={jobResultsRoute(job, 'failure')}
            title={`${job.failureCount} failing run(s)`}
            className="ml-px inline-block bg-red-100 hover:bg-red-200 text-red-700 hover:text-red-800 border border-red-200 font-medium text-xs mt-px py-px px-2 rounded-sm"
          >
            {job.failureCount}
          </Link>
        )}
      </td>
      <td>
        <ul className="flex items-center justify-end">
          <li className="mx-1">
            <Link
              to={routes.applicationJob({
                applicationName: job.application.name,
                name: job.name,
              })}
              title="View Details"
              className="underline"
            >
              <DetailIcon />
            </Link>
          </li>
          {/* <li className="mx-1">
            <Link
              to={routes.applicationJob({
                applicationName: job.application.name,
                name: job.name,
              })}
              title="Edit"
              className="underline"
            >
              <EditIcon />
            </Link>
          </li>
          <li className="mx-1">
            <Link
              to={routes.applicationJob({
                applicationName: job.application.name,
                name: job.name,
              })}
              title="Delete"
              className="underline text-red-700"
            >
              <DeleteIcon />
            </Link>
          </li> */}
        </ul>
      </td>
    </tr>
  )
}

export default JobRow
