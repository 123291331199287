import { Link, routes, useParams } from '@redwoodjs/router'
import { useApolloClient } from '@redwoodjs/web'
import { useState, useEffect } from 'react'
import { QUERY as ApplicationsQuery } from 'src/components/ApplicationsCell'

import JobFilters from 'src/components/JobFilters'
import JobRow from 'src/components/JobRow'

import { titleCase } from 'src/lib/helpers'
import { useQueryParams } from 'src/lib/hooks'

export const QUERY = gql`
  query JobsQuery {
    jobs {
      name
      enabled
      verb
      endpoint
      runEvery
      lastRunAt
      nextRunAt
      successCount
      failureCount
      application {
        name
      }
    }
  }
`

const SAMPLE_CODE = `// Create a Job via the GraphQL API at https://api.repeater.dev/graphql
// Be sure to include your application token in an Authorization header:
//
//   Authorization: Bearer {token}

mutation {
  createJob(
    name: "first-job"
    endpoint: "https://google.com"
    verb: "get"
    runAt: ${new Date().toISOString().replace(/\.\d{3}/, '')}
  ) {
    name
  }
}

// Or with the repeaterdev-js library:

const { repeater } = require('repeaterdev-js')
const repeater = new Repeater('{token}')
repeater.createJob({
  name: "first-job",
  endpoint: "https://google.com",
  verb: "get",
  runAt: ${new Date().toISOString().replace(/\.\d{3}/, '')}
}).then(job => console.log(job))
`

const firstJobSample = (token) => {
  return SAMPLE_CODE.replaceAll('{token}', token)
}

export const Loading = () => (
  <div className="text-base text-center text-gray-500">Loading...</div>
)

export const Empty = () => {
  const { applicationName, filter } = useParams()
  const client = useApolloClient()
  let sampleApp = null

  try {
    const data = client.readQuery({
      query: ApplicationsQuery,
    })
    if (applicationName) {
      sampleApp = data.applications.find((app) => app.name === applicationName)
    } else if (data.applications.length) {
      sampleApp = data.applications[0]
    }
  } catch (e) {
    sampleApp = {
      name: 'Sample',
      token: 'your-token-here',
    }
  }

  return (
    <>
      <div className="text-base text-center text-gray-500">
        <p>
          No jobs found
          {filter && ` for ${titleCase(filter)} filter`}.
        </p>
        {!filter && sampleApp && (
          <p>
            <Link to={routes.newJob({ applicationName })} className="underline">
              Create one?
            </Link>
          </p>
        )}
      </div>

      {!filter && sampleApp && (
        <div className="mt-12 max-w-2xl mx-auto">
          <pre className="mt-8 text-xs font-mono bg-gray-200 rounded-lg p-4">
            <code>{firstJobSample(sampleApp.token)}</code>
          </pre>
        </div>
      )}
    </>
  )
}

export const Failure = ({ error }) => (
  <div className="text-base text-center text-red-700">{error.message}</div>
)

export const Success = ({ applicationName, jobs }) => {
  const [filter, setFilter] = useState('all')
  const params = useQueryParams()

  useEffect(() => {
    setFilter(params.filter || 'all')
  }, [params])

  const filteredJobs = () => {
    let resultSet = jobs

    if (applicationName) {
      resultSet = resultSet.filter(
        (r) => r.application.name === applicationName
      )
    }

    switch (filter) {
      case 'scheduled':
        resultSet = resultSet.filter((j) => j.nextRunAt !== null)
        break
      case 'success':
        resultSet = resultSet.filter((j) => j.successCount > 0)
        break
      case 'failure':
        resultSet = resultSet.filter((j) => j.failureCount > 0)
        break
      case 'pending':
        resultSet = resultSet.filter(
          (j) => j.successCount === 0 && j.failureCount === 0
        )
        break
    }

    return resultSet
  }

  if (filteredJobs().length === 0) {
    return (
      <>
        <JobFilters applicationName={applicationName} filter={filter} />
        <div className="mt-8">
          <Empty />
        </div>
      </>
    )
  }

  return (
    <div>
      <JobFilters applicationName={applicationName} filter={filter} />
      <table className="mt-4 w-full">
        <thead>
          <tr>
            {!applicationName && <th>App</th>}
            <th>Name</th>
            <th>Endpoint</th>
            <th>Run Every</th>
            <th>Last Run</th>
            <th>Next Run</th>
            <th className="text-center">Enabled</th>
            <th className="text-center">Results</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs().map((job, index) => (
            <JobRow key={index} job={job} applicationName={applicationName} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
